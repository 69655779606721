import { Popover, Box } from "@mui/material";



export default function Notification(props) {
	const {background, onClose, anchorEl, open, maxWidth, minWidth, color, over, left, right, ContainerProps, ...other} = props;

	var anchorWidth = anchorEl?.clientWidth;
	var hor = 'center';
	var nipple = {backgroundColor: background};
	if (open && anchorEl) {
		if (left) {
			hor = 'left';
			nipple.marginLeft = `${anchorWidth/2}px`;
		} else if (right) {
			hor = 'right';
			nipple.marginRight = `${anchorWidth/2}px`;
		} 
	}

	var container = {backgroundColor: background, padding: '12px', borderRadius: '4px', color: {color} || 'inherit', ...ContainerProps}

	return <Popover 
		
		open={open}
		{...other}
		slotProps={{
			paper: {
				sx: {
					color: color || 'inherit',
					borderRadius: '4px',
					'&:before': nipple
				},
				className: `popup_alert ${hor} ${over ? 'over' : ''}`
			}
		}}
		anchorEl={anchorEl}
		onClose={onClose}
		anchorOrigin={{
			vertical: over ? 'top' : 'bottom',
			horizontal: hor,
		  }}
		  transformOrigin={{
			vertical: over ? 'bottom' : 'top',
			horizontal: hor,
		  }}>
		<Box sx={container} minWidth={minWidth} maxWidth={maxWidth} color={color}>
			{props.children}
		</Box>
	</Popover>
}