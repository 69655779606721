import React, { useEffect, useState } from 'react';
import { Box, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Button, Dialog, TextField } from '@mui/material';

import jax from '../helper/jax';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Certifications: React.FC = () => {

	const [certs, setCerts] = useState<any>([]);
	const [editCert, setEditCert] = useState<any>(null);
	
	const nav = useNavigate();
	const theme = useTheme();
	useEffect(()=>{ 
		jax.get('/app/admin/certs').then((res) => {
			setCerts(res.certifications);
		});
	}, []);

	const updateCert = (cert: any, isNew: boolean) => {
		if (isNew) {
			setCerts([...certs, cert]);
		}
		else {
			let newCerts = certs.map((c: any) => {
				return c.id === cert.id ? cert : c;
			}); 
			setCerts(newCerts);
		}
	}

	return <Box pt={2}>
		<TableContainer >
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Certificate Type</TableCell>
						<TableCell align='center'># Issued</TableCell>
						
						<TableCell>Description</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{certs.map((c: any, i: number) => {
						return <React.Fragment key={i}>
							<TableRow key={c.id} hover sx={{cursor: 'pointer'}} onClick={()=>setEditCert(c)}>
								<TableCell>{c.name}</TableCell>
								<TableCell align='center'>{c.issued}</TableCell>
								
								<TableCell className="sub-title">{c.description}</TableCell>
							</TableRow>
						</React.Fragment>;
					})}
					<TableRow hover sx={{cursor: 'pointer', "&:hover": {backgroundColor: `${theme.palette.primary["pale"]} !important`}}} onClick={()=>setEditCert({name:"New Module", description:""})}>
						<TableCell colSpan={3}>
							<Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} color={theme.palette.primary.main}>
								<Box lineHeight={0}><Add/></Box>
								<Box fontWeight="bold">New Certificate Type</Box>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			
		</TableContainer>

		<NewCertDialog editCert={editCert} onClose={()=>setEditCert(null)} onChange={updateCert}>	</NewCertDialog>
	</Box>;
};

const NewCertDialog : React.FC<any> = (props: any) => {

	const {editCert, onClose, onChange} = props;

	const { handleSubmit, reset, control, register, watch, formState: {errors} } = useForm({
		defaultValues: {
			id: null,
			name: "",
			description: "",
			next_no: 1000,
			prefix: ""
		}
	});

	useEffect(() => {
		if (editCert) {
			reset({
				id: editCert.id || null,
				name: editCert.name || "",
				description: editCert.description || "",
				prefix: editCert.prefix || "",
				next_no: editCert.next_no || 1000
			});
		}
	}, [editCert]);

	const submit = (data: any) => {
		let isNew = !editCert.id;
		jax.post(`/app/admin/certs${editCert.id ? '/'+editCert.id : ''}`, data).then((res) => {
			onChange({...editCert, ...res}, isNew);
			onClose();
		});
	}

	return <Dialog open={!!editCert} onClose={onClose} fullWidth maxWidth="xs" PaperProps={{
		component: 'form',
		onSubmit: handleSubmit(submit)
	  }}>
		<Box className="card-header">
			{!editCert?.id ? "Create a new Certificate Type" : `Edit ${editCert.name} Certificate Type`}
		</Box>
		<Stack spacing={2} p={2} pt={3}>
			
			<Controller name="name" control={control} rules={{required: "Required"}} render={({field}) => {
				return <TextField {...field} size="small" label="Certificate Name" fullWidth error={!!errors.name} helperText={errors.name?.message}></TextField>
			}}/>
			<Controller name="description" control={control} render={({field}) => {
				return <TextField {...field} size="small" label="Description" multiline rows={2} fullWidth></TextField>
			}}/>
			<Box className="sub-title">
				Issued certificates will be sequentially numbered, beginning with the number below.  An optional prefix can be added for this certificate type.
			</Box>
			<Stack direction="row" alignItems="center" spacing={1}>
				<Box flex={1}>
					<Controller name="prefix" control={control} rules={{maxLength: 5}} render={({field}) => {
						return <TextField {...field} size="small" label="Prefix" fullWidth error={!!errors.name} helperText={errors.name?.message}></TextField>
					}}/>
				</Box>
				<Box lineHeight={1} fontWeight="bold">
					-
				</Box>
				<Box flex={2}>
					<Controller name="next_no" control={control} rules={{required: "Required", max: 1000000}} render={({field}) => {
						return <TextField {...field} type="number" size="small" label="Next Issue #" fullWidth error={!!errors.next_no} helperText={errors.name?.message}></TextField>
					}}/>
				</Box>
				<Box flex={2}>

				</Box>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Button size="small" onClick={onClose}>Cancel</Button>
				<Button size="small" type="submit" variant="contained">Create Certificate Type</Button>
			</Stack>
		</Stack>
		
	</Dialog>
}

export default Certifications;