import { useEffect } from "react";
import jax from "../helper/jax";
import { useState } from "react";
import { Card, Box, List, ListItem, ListItemButton, Divider, Stack, useMediaQuery, Breadcrumbs, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Add, Lock } from "@mui/icons-material";
import { hasRole, roles } from "../features/access";
import NewProgram from "../components/NewProgram";
import Program from "./Program";

export default function Programs(props) {
	const {unit, onShowProfile} = props;
	const [programs, setPrograms] = useState([]);
	const nav = useNavigate();
	const [creating, setCreating] = useState(false);
	const user = useSelector((state) => state.data.user);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

	const params = useParams();

	useEffect(()=>{

		if (params.object_id) return; //This is a detail page

		jax.get('/app/programs', unit ? {unit_id: unit.id}  : null).then((programs)=>{
			setPrograms(programs);
		});
	}, [params.object_id]);

	return !params.object_id ? <Stack spacing={0}>
		<Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{p: 2}}>
			{hasRole(user, roles.ADMIN) && <Button variant="contained" onClick={()=>setCreating(true)} size="small" startIcon={<Add/>}>
				New Program
			</Button>}
		</Stack>
		
		<Divider></Divider>

		<Box className="full-height" sx={{whiteSpace:"nowrap"}}>
			{programs && programs.length == 0 && <Box p={4} textAlign="center">There are no training programs associated with this command...</Box>}
			<List sx={{overflowY:"auto", height:"100%"}}>
				{programs?.map((pgm, i) => {
					return <div key={i}>
						<ListItem onClick={()=>nav(pgm.code)} key={i}  sx={{p:0, display:'block'}}>
							<ListItemButton sx={{display:'block',p:{xs:2}}}>
								<Stack alignItems={{xs: 'flex-start', lg: 'center'}} justifyContent="flex-start" direction="row" spacing={{xs:0, lg:2}}>
									<Stack alignItems="flex-start" flex={{xs:12, lg:4}} mt={0} mb={{xs:1, lg:0}}>
										<div><b>{pgm.name}</b></div>
										<Stack direction={{xs:'column', lg:'row'}} spacing={{xs:0.5, lg:1}} mt={0.5}>
											<div className="sub-title">{pgm.command}</div>
											{!isMobile && <div className="sub-title">&#183;</div>}
											<div className="sub-title">{pgm.location}</div>
										</Stack>
									</Stack>
									{!isMobile && <>
										<Stack alignItems="center" flex={{xs:6, lg:1}}>
											<div><b>Courses</b></div>
											<div className="sub-title">{pgm.courses || '-'}</div>
										</Stack>
										<Stack alignItems="center" flex={{xs:6, lg:1}}>
											<div><b>Graduates</b></div>
											<div className="sub-title">{pgm.graduates || '-'}</div>
										</Stack>
										<Stack alignItems="center" flex={{xs:6, lg:1}}>
											<div><b>IUT Grads</b></div>
											<div className="sub-title">{pgm.iuts || '-'}</div>
										</Stack>
										</>}									
								</Stack>
							</ListItemButton>
						</ListItem>
						{i < programs.length-1 && <Divider></Divider>}
					</div>;
				})}
			</List>

			
		</Box>
		
		<NewProgram open={creating} onClose={()=>setCreating(false)} defaultUnit={unit}></NewProgram>
	</Stack> : <Program code={params.object_id}></Program>;
}