import { hasAnyRole, roles, unitRoles } from './features/access';
import Course from './training/Course';
import Courses from './training/Courses';
import Home from './training/Home';

//import CourseReport from './training/reports/Course';
import Programs from './commands/Programs';
import Program from './commands/Program';


import Personnel from './admin/Personnel';

import SafetyReport from './training/SafetyReport';
import SafetyReports from './training/SafetyReports';
import { Card } from '@mui/material';
import React, { Suspense } from 'react';

// import Commands from './commands/Commands';
import Evaluations from './components/eval/editor/Evaluations.tsx';
import EvalEditor from './components/eval/editor/EvalEditor.tsx';
import CourseTypes from './admin/CourseTypes.tsx';
import CourseType from './admin/CourseType.tsx';
import Modules from './admin/Modules.tsx';
import Module from './admin/Module.tsx';
import Roles from './admin/Roles.tsx';
import Reports from './admin/Reports.tsx';
import Certifications from './admin/Certifications.tsx';
const LazyCommands = React.lazy(() => import('./commands/Home.js'));
const LazyAdmin = React.lazy(() => import('./admin/Home.tsx'));
const LazyCourseReport = React.lazy(() => import('./training/reports/CourseEvals'));


const system =  [
	{
		title: "Home",
		location: "/",
		element: <Home />
	},
	{
		title: "Courses",
		location: "/courses",
		element: <Courses/>,
		roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
		routes: [{
			location: ":course_id",
			roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
			element: <Course/>
		},{
			location: ':course_code/report',
			element: <Suspense><LazyCourseReport/></Suspense>
		}
		]
	},
	{
		title: "Command Management",
		location: "/commands",
		feature_id: 16,
		unitRoles: [unitRoles.ADMIN, unitRoles.COMMAND_MGR, unitRoles.PERSONNEL_MGR, unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.DONOR_MGR, unitRoles.MEDICAL_OFFICER, unitRoles.PROGRAM_ADMIN],
		element: <Suspense><LazyCommands/></Suspense>,
		requireEmailVerification: false,
		routes: [{
			location: ":unit_id",
			element: <Suspense><LazyCommands/></Suspense>
		},
		{
			location: ":unit_id/:tab",
			element: <Suspense><LazyCommands/></Suspense>
		},
		{
			location: ":unit_id/:tab/:object_id",
			element: <Suspense><LazyCommands/></Suspense>
		}]
	},
	{
		title: "Administration",
		location: "/admin",
		adminOnly: true,
		unitRoles: [unitRoles.ADMIN],
		element: <Suspense><LazyAdmin><Evaluations isActive={true}/></LazyAdmin></Suspense>,
		requireEmailVerification: false,
		routes: [{
			location: "evals",
			element: <Suspense><LazyAdmin><Evaluations isActive={true}/></LazyAdmin></Suspense>
		},
		{
			location: "programs",
			element: <Suspense><LazyAdmin><Programs/></LazyAdmin></Suspense>
		},
		{
			location: "programs/:code",
			element: <Suspense><LazyAdmin><Program/></LazyAdmin></Suspense>
		},
		{
			location: "evals/:uuid",
			element: <Suspense><LazyAdmin><EvalEditor/></LazyAdmin></Suspense>
		},
		{
			location: "evals/:type",
			element: <Suspense><LazyAdmin><EvalEditor/></LazyAdmin></Suspense>
		},
		{
			location: "personnel/:user_id",
			element: <Suspense><LazyAdmin><Personnel isActive={true}/></LazyAdmin></Suspense>
		},
		{
			location: "personnel",
			element: <Suspense><LazyAdmin><Personnel isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "reports",
			element: <Suspense><LazyAdmin><Reports isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "course_types/:id",
			element: <Suspense><LazyAdmin><CourseType isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "course_types",
			element: <Suspense><LazyAdmin><CourseTypes isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "modules/:uuid",
			element: <Suspense><LazyAdmin><Module isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "modules",
			element: <Suspense><LazyAdmin><Modules isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "roles",
			element: <Suspense><LazyAdmin><Roles isActive={true}/></LazyAdmin></Suspense>
		},{
			location: "certification",
			element: <Suspense><LazyAdmin><Certifications isActive={true}/></LazyAdmin></Suspense>
		}]
	},
	// {
	// 	title: "Personnel",
	// 	location: "/personnel",
	// 	adminOnly: true,
	// 	unitRoles: [unitRoles.ADMIN],
	// 	element: <Card><Personnel isActive={true}/></Card>,
	// 	routes: [{
	// 		location: ":user_id",
	// 		element: <Card><Personnel/></Card>
	// 	}]
	// },
	{
		title: "Safety Reports",
		location: "/safety_reports",
		feature_id: 1,
		roles: [roles.ADMIN, roles.PROGRAM_DIR, roles.PROGRAM_MGR, roles.MED_DIR],
		element: <Card><SafetyReports/></Card>, 
		routes: [{
			location: ":uuid",
			element: <Card><SafetyReport/></Card>
		}]
	},
];

export default system;


