import React, { useEffect, useState } from 'react';
import { List, Box, ListItemButton, Stack, Divider, ListItem, Collapse, Chip, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, Card, useTheme } from '@mui/material';
import jax from '../../../helper/jax';
import { useNavigate } from 'react-router-dom';
import { asDate, copyToClipboard } from '../../../helper/util';
import { Add, CopyAll, ExpandLess, ExpandMore, Lock, Publish, Science, Unpublished } from '@mui/icons-material';
import { Dialog, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import Eval from '../Eval';

interface EvaluationsProps {
	// Add any props you need for your component here
}

const Evaluations: React.FC<EvaluationsProps> = () => {
	// Add your component logic here


	const [evals, setEvals] = useState<{}>({});
	const [expanded, setExpanded] = useState<Array<number>>([]);
	const [testSchema, setTestSchema] = useState<string | null>(null);
	const [showNew, setShowNew] = useState(false);

	const nav = useNavigate(); 
	const theme = useTheme(); 
	
	useEffect(()=>{
		jax.get('/app/evaluations').then((res) => {
			setEvals(res.evals || {});
		});
	}, []);

	const toggle = (id: number) => {
		if(expanded.includes(id)) {
			setExpanded(expanded.filter((e) => e != id));
		} else {
			setExpanded([...expanded, id]);
		}
	}

	const publish = (eval_id: number, schema_id: number) => {
		jax.post(`/app/evaluations/publish`, {id: schema_id}).then((res) => {
			const newEvals = {...evals};
			newEvals[eval_id].versions = newEvals[eval_id].versions.map((v: any) => {
				if(v.id == schema_id) {
					v.published = true;
				}
				return v;
			});
			setEvals(newEvals);
		});
	}

	const unpublish = (eval_id: number, schema_id: number) => {
		jax.post(`/app/evaluations/unpublish`, {id: schema_id}).then((res) => {
			const newEvals = {...evals};
			newEvals[eval_id].versions = newEvals[eval_id].versions.map((v: any) => {
				if(v.id == schema_id) {
					v.published = false;
				}
				return v;
			});
			setEvals(newEvals);
		});
	}

	const copyLink = (link: string) => {
		copyToClipboard(link);
		
	}

	const create = (e: any) => {
		e.preventDefault();
		
		let data = new FormData(e.target);
		console.log(Object.fromEntries(data));
		jax.post('/app/evaluations', Object.fromEntries(data)).then((res) => {
			nav(res.schema.toString());	
		}).catch(err=>console.error(err));
	}

	return <>
		<Dialog open={showNew} onClose={()=>setShowNew(false)} fullWidth maxWidth="xs" PaperProps={{
			component: 'form',
			onSubmit: create,
		  }}>
			<DialogContent sx={{padding:0}}>
				<Box className="card-header">
					New Course Type
				</Box>
				<Stack spacing={2} p={2} pb={1}>
					<Box><Typography variant="body1" color="textSecondary">Enter a name for the new evaluation type</Typography></Box>
					<TextField name="name" size="small" label="Evaluation Type Name" fullWidth></TextField>
				</Stack>
				
			</DialogContent>
			<DialogActions sx={{px:2, pb:2, justifyContent:"space-between"}}>
				<Button size="small" onClick={()=>setShowNew(false)}>Cancel</Button>
				<Button size="small" type="submit" variant="contained">Create Evaluation Type</Button>
			</DialogActions>
			
		</Dialog>

		<TableContainer sx={{mt:2}}>
			<Table size="small">
				<TableHead>
					<TableRow >
						<TableCell width="5%"/>
						<TableCell width="40%">Name</TableCell>
						<TableCell align="center">Submissions</TableCell>
						<TableCell align="center">Versions</TableCell>
						<TableCell>Last Updated</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{evals && Object.keys(evals).map((k, i) => {
						const e = evals[k];
						return <React.Fragment key={i}>
							<TableRow  className={expanded.includes(parseInt(e.id)) ? 'expand' : ''} onClick={()=>toggle(e.id)} hover sx={{ '& > *': {cursor: 'pointer'}, '&:not(.expand) > td': { borderBottom: 'unset'}}}>
								<TableCell width="5%">
									<IconButton
										size="small"
										onClick={() => toggle(e.id)}
									>
										{expanded.includes(parseInt(e.id)) ? <ExpandLess /> : <ExpandMore />}
									</IconButton>
								</TableCell>
								<TableCell><b>{e.name}</b></TableCell>
								<TableCell align="center">
									{!!e.submissions && <b>{e.submissions}</b>}
									{!e.submissions && <span style={{opacity: 0.25}}>{e.submissions}</span>}
								</TableCell>
								<TableCell align="center">{e.versions.length}</TableCell>
								<TableCell>{asDate(e.updated)}</TableCell>
							</TableRow>
							<TableRow sx={{ '& > *': { borderBottom: 'unset', '& tr td': {cursor: 'pointer' }} }}>
								<TableCell sx={{padding: 0}} colSpan={5}>
									<Collapse in={expanded.includes(parseInt(e.id))} >
										<Box p={2} bgcolor="#f0f0f0">
											<Card >
												<Table size="small"> 
													<TableHead>
														<TableRow>
															<TableCell width="25%">Version</TableCell>
															<TableCell >Status</TableCell>
															<TableCell align="center">Submissions</TableCell>
															<TableCell>Last Updated</TableCell>
															<TableCell></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{e.versions.map((v: any, j: number) => {
															return <React.Fragment key={j}>
																<TableRow hover onClick={()=>nav(`/admin/evals/${v.uuid}`)} sx={{color: !v.published ? "#aaa" : 'black'}}>
																	<TableCell width="25%" className="inherit">Version {v.version}</TableCell>
																	<TableCell >
																		<Stack direction="row" alignItems="center" spacing={1}>
																			{!v.published && <Chip label="Draft" sx={{borderColor:'#ccc', color: '#999999'}} variant="outlined" size="small" />}	
																			{!!v.published && <Chip label="Published" color="success" variant="filled" size="small" />}	
																			{v.submissions > 0 && <Chip sx={{padding: '0 4px', borderColor:'#cccccc', color: '#999999'}} label="In Use" icon={<Lock/>} variant="outlined" size="small" />}
																		</Stack>
																	</TableCell>
																	<TableCell align="center">
																		{!!v.submissions && <b>{v.submissions}</b>}
																		{!v.submissions && <span style={{opacity: 0.25}}>{v.submissions}</span>}
																	</TableCell>
																	<TableCell className="inherit">{asDate(v.updated)}</TableCell>
																	<TableCell align='right'>
																		<Stack direction="row" spacing={2}>
																			
																			{!v.published && <Button size="small" color="primary" variant="contained" onClick={(ev)=>{ev.stopPropagation(); publish(e.id, v.id)}} startIcon={<Publish/>}>Publish</Button>}
																			{!!v.published && <Button size="small" color="primary" variant="outlined" onClick={(ev)=>{ev.stopPropagation(); unpublish(e.id, v.id)}} startIcon={<Unpublished/>}>Unpublish</Button>}
																			{v.link && v.published && <Button size="small" color="primary" variant="text" onClick={(ev)=>{ev.stopPropagation(); copyLink(v.link);}} startIcon={<CopyAll/>}>Copy Link</Button>}
																			<Button size="small" color="info" variant="text" onClick={(ev)=>{ev.stopPropagation(); setTestSchema(v.uuid); }} startIcon={<Science/>}>Test</Button>
																		</Stack>
																	</TableCell>
																</TableRow>
															</React.Fragment>;
														})}
													</TableBody>
												</Table>
											</Card>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</React.Fragment>;
					})}
					<TableRow hover sx={{cursor: 'pointer', "&:hover": {backgroundColor: `${theme.palette.primary["pale"]} !important`}}} onClick={()=>setShowNew(true)}>
						<TableCell colSpan={5}>
							<Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} color={theme.palette.primary.main}>
								<Box lineHeight={0}><Add/></Box>
								<Box fontWeight="bold">New Evaluation Type</Box>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>

		<Eval schema_id={testSchema} show={!!testSchema} onClose={()=>setTestSchema(null)}/>


	{/* <List  sx={{ paddingBottom: '0 !important' }} className="course-list">
		
			{evals && Object.keys(evals).map((k, i) => {
				const e = evals[k];
				return <>
						<ListItemButton onClick={()=>toggle(e.id)}>
							<Stack flex={2} key={i} direction="row" alignItems="center" justifyContent="space-between">
								<Stack spacing={0.5} flex={3}>
									<Box>
										<b>{e.name}</b>
									</Box>
								</Stack>
								<Box flex={1} className="sub-title">{e.submissions} Submission{e.submissions == 1 ? "" : "s"}</Box>
								
								<Box textAlign="right" flex={2} mr={2} className="sub-title"><Chip label={`Version ${e.versions.length}`} color="primary" variant="outlined" size="small"/></Box>
								
							</Stack>
							{expanded.includes(parseInt(e.id)) ? <ExpandLess /> : <ExpandMore />}
							
						</ListItemButton>
						
						<Collapse in={expanded.includes(parseInt(e.id))}>
							<Divider />
							<List>
								{e.versions.map((v: any, j: number) => {
									return <>
										
											<ListItemButton onClick={()=>nav(e.id + '/' + v.version)}>
												<Stack flex={2} key={i} direction="row" alignItems="center" justifyContent="space-between">
													
													<Stack direction="row" alignItems="center"  flex={3}>
														<Box pl={4} pr={1}><b>Version {v.version}</b></Box>	
														{!v.published && <Chip label="Draft" color="grey" variant="outlined" size="small" />}	
														{!!v.published && <Chip label="Published" color="primary" variant="filled" size="small" />}	
													</Stack>
														
													<Box flex={1} className="sub-title">Updated {asDate(v.updated)}</Box>	
													<Stack direction="row" spacing={1} mr={2} flex={2} className="sub-title" textAlign="right" justifyContent="flex-end">
														<Button size="small" color="primary" variant="contained" startIcon={<Publish/>}>Publish</Button>
													</Stack>	
												</Stack>
												
												<ExpandLess sx={{visibility:'hidden'}}/>
											</ListItemButton>
											
											{j < e.versions.length-1 && <Divider/>}
										
									</>;
								})}
							</List>
						</Collapse>
						<Divider/>
					</>;
			})}
		</List> */}
		</>;
};

export default Evaluations;