import { configureStore } from '@reduxjs/toolkit'
import dataReducer from './features/data';
import appReducer from './features/app';

const store = configureStore({
  reducer: {
	  data: dataReducer,
    app: appReducer
  },
});

const hasFeature = (feature) => {
  return (store.getState().app.tenantInfo?.ff & feature) == feature;
}

export default store;
export {hasFeature};