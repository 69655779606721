import { Box, Button, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import jax from "../helper/jax";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UnitSelector from "./UnitSelector";


export default function NewProgram(props) {
	const {open, onClose, onCreate, defaultUnit} = props;
	const nav = useNavigate();
	const create = async function(data) {
		try {
			let url = '/app/admin/programs';
			if (unit){
				url = `/app/admin/units/${unit.id}/programs`;
			}
			var result = await jax.put(url, data);
			if (result.code) {
				if (unit) {
					nav(`/commands/${unit.id}/programs/${result.code}`);
				} else {
					nav(`/admin/programs/${result.code}`);
				}
				return;
			}
			if (onCreate) {
				onCreate(result.code);
			}
		} catch (err) {
			debugger;
		}
	}
	const [commands, setCommands] = useState([]);
	const user = useSelector((state) => state.data.user);
	const [unit, setUnit] = useState(defaultUnit);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
	
	const {control , handleSubmit, formState: { errors }} =  useForm({
		defaultValues: {
			name: '',
			unit_id: ''
		}
	});

	useEffect(()=>{
		if (open) {
			setUnit(defaultUnit);
		}
	}, [open])

	return <Dialog open={open} fullScreen={isMobile} >
		<form onSubmit={handleSubmit(create)} noValidate>
			<Box className="card-header">
				New Program
			</Box>
			<Box p={1} pb={0}>
				
					
					<Box minWidth={isMobile ? 0 : 400}>
					
						<Stack spacing={2} p={1}>
							<Controller name="name" control={control} rules={{required: 'Required'}} render={({ field }) =>
								<TextField label="Program Name" size="small" required {...field} error={!!errors.name}></TextField>
							}/>
							<FormControl size="small">
								
								
									<UnitSelector options={commands} parent={defaultUnit?.id}  unit={unit} onSelect={(v)=>setUnit(v)}/>
								
							</FormControl>
							
						</Stack>
					</Box>
				
			</Box>
			<DialogActions sx={{px:2}}>
				
				
					<Button variant="outlined" onClick={onClose} sx={{mr:1}}>Cancel</Button>
					<Box sx={{flex:1}}></Box>
					<Button variant="contained" type="submit">Create</Button>
				
			</DialogActions>
		</form>
	</Dialog>
}