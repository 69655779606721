import { Autocomplete, Avatar, Box, InputLabel, FormControl, ListItemButton, Popper, Stack, Typography, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { debounce } from "../helper/util";
import { TextField } from "@mui/material";
import { FindInPage } from "@mui/icons-material";
import { hasRole, roles, unitRoleNames, unitRoles } from "../features/access";
import { getSearchClient } from "../helper/search";
import { useForm } from "react-hook-form";

const AutoWidthPopper = function(props) {
	return <Popper {...props} style={{width: 'fit-content'}} placement="bottom-start" />;
}

export default function UnitSearch(props) {
	const {onSelect, onTextChange, index, InputProps, unit, error, parent, defaultText, label, managedOnly, clearOnSelect} = props;
	const {config} = useSelector((state) => state.data);

	const tenantInfo = useSelector(state=>state.app.tenantInfo);
	const user = useSelector(state=>state.data.user);
	const search_client = getSearchClient(tenantInfo?.sk);
	const search_index = search_client.initIndex(index || config.unit_index);
	
	const [searchTerm, setSearchTerm] = useState(unit?.name || defaultText || '');
	const [searchHits, setSearchHits] = useState([]);
	const {agencies} = useSelector(state=>state.data);

	

	const search = (term)=>{
		
		
		var filter = [];
		
		if (managedOnly && !hasRole(user, roles.ADMIN)) {
			
			
			filter = Object.keys(user.unit_summary).map(key=>{
				if (key.indexOf('-') > -1) {
					var [start, end] = key.split('-');
					return `(id:${start} TO ${end})`;
				} else {
					return `(id:${key} TO ${key})`;
				}
			});			
		}

		if (parent) {
			filter.push(`parent_ids:${parent}`);	
		}
			
		search_index.search(term || '*', {
			//attributesToRetrieve: ['uid', 'name', 'role_id','affiliation_id', 'agency_id', 'rank_id', 'photo'],
			filters: filter.join(' OR '),
			hitsPerPage: 25,
			//page: page-1,
		}).then((resp ) => {
			var us = resp.hits.map(h=>{
				return { ...h, unit_name: h.name, unit_id: h.id};
			})
			setSearchHits(us);
		});
	}

	useEffect(()=>{
		onTextChange && onTextChange(searchTerm);
		if (searchTerm?.length > 2) {
			search(searchTerm);
		} else {
			setSearchHits([]);
		}
	}, [searchTerm]);

	return <FormControl fullWidth>

		<Autocomplete 
			freeSolo 
			PopperComponent={AutoWidthPopper} 
			getOptionLabel={(unit)=> {
				return typeof unit === 'string' ? unit : unit.unit_name
			}} 
			clearOnBlur={false} 
			autoHighlight
			onInputChange={(e,v)=>!unit && setSearchTerm(v)} 
			options={searchHits} 
			filterOptions={(x) => x} 
			isOptionEqualToValue={(option, value) => option.id === value.id}
			size="small" 
			
			label={label || "Assigned Command"}
			onChange={(e,v)=>{setSearchTerm(''); onSelect && onSelect(v); }}
			value={unit ? unit : searchTerm} 
			defaultValue={defaultText}
			renderInput={(params) => <TextField id="unit-search" error={!!error} helperText={error} sx={{minWidth:"250px"}} {...params} InputLabelProps={{shrink:true}} label={label || "Assigned Command"} />} fullWidth renderOption={(props, unit, state, ownerState)=>{
				var agency = agencies.find(x=>x.id == unit.agency_id);
				return <ListItemButton {...props} key={unit.id} onClick={()=>{setSearchHits([]); clearOnSelect && setSearchTerm(''); onSelect && onSelect(unit); }}><Stack direction="row" justifyContent="flex-start" alignItems="center" flex={1}>
					<Box>
						<Avatar src={`/images/agency/${agency?.abbr}.png`} sx={{border:"solid 2px white", height: 30, cursor: 'pointer', width: 30, mr:1 }} />
					</Box>
					<Box>
						<Typography fontWeight={500} whiteSpace="nowrap">{unit.unit_name}</Typography>
						<Box className="sub-title">{unit.location}</Box>
					</Box>
					
				</Stack></ListItemButton>;
		}} />
	</FormControl>;
}