
import { Delete, DragHandleRounded } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import React, {PropsWithChildren, ReactNode} from 'react';
import { SortableContainer, SortableElement, SortableHandle, SortableContainerProps, SortableElementProps } from 'react-sortable-hoc';

interface SortableItemProps extends SortableElementProps {
	text: string;
	id: string | number;
	allowDelete?: boolean;
	allowDrag?: boolean;
	onDelete?: (id: string | number) => void;
	onClick?: (id: string | number) => void;
}

const DragHandle = SortableHandle(() => <DragHandleRounded sx={{cursor: 'ns-resize'}}/>);

export const SortableItem = SortableElement<SortableItemProps>((props: SortableItemProps) => {
	const {text, id, onDelete, onClick, allowDrag, allowDelete} = props;
	return <Button className="sortable-item" data-id={id} fullWidth size="medium" variant="outlined" sx={{whiteSpace: 'nowrap', textOverflow:'ellipsis', display:'flex', '& .MuiButton-startIcon': {flex:0}}} startIcon={allowDrag && <DragHandle/>} endIcon={allowDelete && <Delete onClick={()=>!!onDelete && onDelete(id)}/>} onClick={()=>{}}><Box flex={1} textAlign="left">{text}</Box></Button>
})

type SortableListProps = SortableContainerProps & {
	allowDelete?: boolean;
	allowDrag?: boolean;
	onDelete?: (id: string | number) => void;
};

export const SortableList = SortableContainer<PropsWithChildren<SortableListProps>>((props: PropsWithChildren<SortableListProps>) => {
	const { children, allowDelete, allowDrag, onDelete} = props;
	
	return <Stack spacing={1}>
		{children && React.Children.map(children, (child: any, index: number) => {
			return child && React.cloneElement(child, {allowDelete, allowDrag, onDelete: onDelete});
		})}
		
	</Stack>;
});


