import { Box, Button, Divider, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import jax from '../helper/jax';
import { Controller, useForm } from 'react-hook-form';
import { Report } from '../training/reports/CourseEvals';

const Reports: React.FC = (props : any) => {

	const {isActive} = props;
	const [evals, setEvals] = useState<Object | null>(null);
	const [data, setData] = useState<Object | null>(null);

	const {user} = useSelector((state: any) => state.data);
	const {handleSubmit, control, setValue, watch} = useForm({
		defaultValues: {
			type_id: "",
			schema_id: "",
			program_id: "",
			role_id: ""
		}
	});	

	let type_id = watch("type_id");

	useEffect(() => {
		jax.get('/app/evaluations').then((res) => {
			setEvals(res.evals || {});
		});
	}, []);

	function runReport(data: any) {
		jax.post('/app/evaluations/reports', data).then((res) => {
			setData(res.reports[0]);
		});
	}

	return (
		<Stack pt={2}>
			<form onSubmit={handleSubmit(runReport)}>
				<Stack direction="row" p={2} spacing={2}>
					<Box flex={1}>
						<FormControl fullWidth size='small'>
							<InputLabel id="type_label">Evaluation</InputLabel>
							<Controller control={control} name="type_id" rules={{required: 'Required'}}  render={({field})=> {
								return <Select labelId='type_label' size="small" {...field} label="Evaluation" fullWidth>
									{evals && Object.keys(evals || {}).map((k,i) => {
										return <MenuItem key={i} value={k}>{evals[k].name}</MenuItem>
									})}
								</Select>;
							} } />
						</FormControl>
					</Box>
					<Box flex={1}>
						<FormControl fullWidth disabled={!type_id} size='small'> 
							<InputLabel  id="schema_label">Version</InputLabel>
							<Controller control={control} name="schema_id" render={({field})=> {
								return <Select size="small" labelId='schema_label' placeholder='All' {...field} label="Version" fullWidth>
									<MenuItem value="">All</MenuItem>
									{!!type_id && evals &&  evals[type_id].versions.map((v,i) => {
										if (!v.published) return null;
										return <MenuItem key={i} value={v.id}>Version {v.version}</MenuItem>
									})}
								</Select>;
							} } />
						</FormControl>
					</Box>
					<Box flex={1}>
						<FormControl fullWidth size='small'>
							<InputLabel  id="program_label">Program</InputLabel>
							<Controller control={control} name="program_id" render={({field})=> {
								return <Select size="small" labelId='program_label' placeholder='All' label="Program" {...field}  fullWidth>
									<MenuItem value="">All</MenuItem>
									{user.programs.map((p,i) => {
										return <MenuItem key={i} value={p.id}>{p.name}</MenuItem>
									})}
								</Select>;
							} } />
						</FormControl>
					</Box>
					<Box flex={1}></Box>
					<Box >
						<Button type="submit" variant="contained" color="primary">Run Report</Button>
					</Box>
				</Stack>
			</form>
			<Divider/>
			<Box px={2} pt={2}>
				{data && <Report data={data} noCourse={true}/>}
			</Box>
		</Stack>
	);
};

export default Reports;