import React, { useEffect } from 'react';
import jax from '../helper/jax';

import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Button, Stack, useTheme, Dialog, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface Props {
	// Define your component's props here
}

const CourseTypes: React.FC<Props> = () => {

	const [types, setTypes] = React.useState<any>([]);
	const [showNew, setShowNew] = React.useState(false);
	
	const nav = useNavigate();
	const theme = useTheme();

	useEffect(() => {
		jax.get('/app/admin/course_types').then((res) => {
			setTypes(res);
		});
	},[]);

	const create = (e: any) => {
		e.preventDefault();
		
		let data = new FormData(e.target);
		console.log(Object.fromEntries(data));
		jax.post('/app/admin/course_types', Object.fromEntries(data)).then((res) => {
			nav(res.id.toString());
		}).catch(err=>console.error(err));
	}

	return <Box pt={2}>
		<Dialog open={showNew} onClose={()=>setShowNew(false)} fullWidth maxWidth="xs" PaperProps={{
			component: 'form',
			
			onSubmit: create,
		  }}>
			<DialogContent sx={{padding:0}}>
				<Box className="card-header">
					New Course Type
				</Box>
				<Stack spacing={2} p={2} pb={1}>
					<Box><Typography variant="body1" color="textSecondary">Enter a name for the new course type</Typography></Box>
					<TextField name="name" size="small" label="Course Type Name" fullWidth></TextField>
				</Stack>
				
			</DialogContent>
			<DialogActions sx={{px:2, pb:2, justifyContent:"space-between"}}>
				<Button size="small" onClick={()=>setShowNew(false)}>Cancel</Button>
				<Button size="small" type="submit" variant="contained">Create Course Type</Button>
			</DialogActions>
			
		</Dialog>

		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Course Type</TableCell>
						<TableCell>Courses</TableCell>
						<TableCell>Active</TableCell>
						<TableCell width="*">Description</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{types.map((t: any) => {
						return <TableRow key={t.id} hover sx={{cursor: 'pointer'}} onClick={()=>nav(t.id.toString())}>
							<TableCell>{t.name}</TableCell>
							<TableCell>{t.courses}</TableCell>
							<TableCell>{t.active ? "Yes" : "No"}</TableCell>
							<TableCell width="*" className="sub-title">{t.description}</TableCell>
						</TableRow>
					})}
					<TableRow hover sx={{cursor: 'pointer', "&:hover": {backgroundColor: `${theme.palette.primary["pale"]} !important`}}} onClick={()=>setShowNew(true)}>
						<TableCell colSpan={4}>
							<Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} color={theme.palette.primary.main}>
								<Box lineHeight={0}><Add/></Box>
								<Box fontWeight="bold">New Course Type</Box>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			
		</TableContainer>
	</Box>;
};

export default CourseTypes;