

import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Alert } from '@mui/material' ;
import jax from '../helper/jax';

const SupportDialog: React.FC = (props: any) => {
	const {open, onClose} = props;
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const handleSend = async () => {
		try {
			setError(false);
			await jax.post('/app/admin/support/send', {message: message});
			setMessage('');
			handleClose();
		} catch (err) {
			setError(true);
		}
	};

	useEffect(()=>{
		setError(false);
	}, [open])

	return (
		<Dialog open={open} fullWidth onClose={handleClose}>
				<DialogTitle>Contact Support</DialogTitle>
				<DialogContent sx={{px:3, pb:2, pt:0}}>
					<Box pb={2}>
						If you have questions, feedback or need support, please enter your message below.  An administrator will reach out if a response is required.
					</Box>
					<TextField
						autoFocus
						multiline
						rows={4}
						margin="dense"
						label="Your Message"
						type="text"
						fullWidth
						size="small"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					{error && <Alert sx={{my:1}} severity="error">An error occurred while sending your message.  Please try again.</Alert>}
				</DialogContent>
				<DialogActions sx={{px:3, pb:2, pt:0}}>
					<Button onClick={handleClose} size="small" color="primary">
						Cancel
					</Button>
					<Box flex={1}></Box>
					<Button onClick={handleSend} size="small" color="primary" variant="contained">
						Send
					</Button>
				</DialogActions>
			</Dialog>
	);
};

export default SupportDialog;