import React, { useEffect, useState } from "react";
import jax from "../helper/jax";
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stack, useTheme, TextField, Button, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";

const Modules : React.FC<any> = (props: any) => {
	
	const [modules, setModules] = useState<any>([]);
	const [editModule, setEditModule] = useState<any>(null);
	const nav = useNavigate();
	const theme = useTheme();
	
	useEffect(() => {
		jax.get('/app/admin/modules').then((res) => {
			setModules(res.modules);
		});
	},[]);

	const updateModule = (module: any, isNew: boolean) => {
		if (isNew) {
			setModules([...modules, module]);
			nav(module.uuid.toString());
		}
		else {
			let newModules = modules.map((m: any) => {
				return m.id === module.id ? module : m;
			}); 
			setModules(newModules);
		}
	}

	return <Box pt={2}>
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Module Name</TableCell>
						<TableCell>Last Modified</TableCell>
						<TableCell>Description</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{modules.map((m: any, i: number) => {
						return <React.Fragment key={i}>
							<TableRow key={m.id} hover sx={{cursor: 'pointer'}} onClick={()=>nav(m.uuid.toString())}>
								<TableCell>{m.name}</TableCell>
								<TableCell>{m.modified}</TableCell>
								<TableCell className="sub-title">{m.description}</TableCell>
							</TableRow>
						</React.Fragment>;
					})}
					<TableRow hover sx={{cursor: 'pointer', "&:hover": {backgroundColor: `${theme.palette.primary["pale"]} !important`}}} onClick={()=>setEditModule({name:"New Module", description:""})}>
						<TableCell colSpan={3}>
							<Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} color={theme.palette.primary.main}>
								<Box lineHeight={0}><Add/></Box>
								<Box fontWeight="bold">New Module</Box>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			
		</TableContainer>

		<NewModuleDialog editModule={editModule} onClose={()=>setEditModule(null)} onChange={updateModule}>	</NewModuleDialog>
	</Box>;
}

const NewModuleDialog : React.FC<any> = (props: any) => {

	const {editModule, onClose, onChange} = props;

	const { handleSubmit, reset, control, register, watch, formState: {errors} } = useForm({
		defaultValues: {
			id: null,
			name: "",
			description: ""
		}
	});

	useEffect(() => {
		if (editModule) {
			reset({
				id: editModule.id || null,
				name: editModule.name || "",
				description: editModule.description || ""
			});
		}
	}, [editModule]);

	const submit = (data: any) => {
		let isNew = !editModule.id;
		jax.post('/app/admin/modules', data).then((res) => {
			onChange({...editModule, ...res}, isNew);
			onClose();
		});
	}

	return <Dialog open={!!editModule} onClose={onClose} fullWidth maxWidth="xs" PaperProps={{
		component: 'form',
		onSubmit: handleSubmit(submit)
	  }}>
		<Box className="card-header">
			{!editModule?.id ? "Create a new Module" : `Edit ${editModule.name} Module`}
		</Box>
		<Stack spacing={2} p={2} pt={3}>
			
			<Controller name="name" control={control} rules={{required: "Required"}} render={({field}) => {
				return <TextField {...field} size="small" label="Module Name" fullWidth error={!!errors.name} helperText={errors.name?.message}></TextField>
			}}/>
			<Controller name="description" control={control} render={({field}) => {
				return <TextField {...field} size="small" label="Description" multiline rows={2} fullWidth></TextField>
			}}/>
			<Stack direction="row" justifyContent="space-between">
				<Button size="small" onClick={onClose}>Cancel</Button>
				<Button size="small" type="submit" variant="contained">Create Module</Button>
			</Stack>
		</Stack>
		
	</Dialog>
}

export default Modules;