import React, { useEffect } from "react";
import jax from "../helper/jax";
import { useParams } from "react-router-dom";
import { Box, Breadcrumbs, Button, IconButton, Skeleton, Stack, TextField } from "@mui/material";
import { ArrowBackIos, Edit } from "@mui/icons-material";
import {Link as RouterLink} from 'react-router-dom';
import ContentExplorer from "../components/ContentExplorer";
import _ from "lodash";


const Module : React.FC<any> = (props: any) => {
	
	const params = useParams();
	const [module, setModule] = React.useState<any | null>(null);
	const [savedModule, setSavedModule] = React.useState<any | null>(null);
	const [editing, setEditing] = React.useState(false);
	const [hasChanges, setHasChanges] = React.useState(false);
	
	useEffect(() => {
		jax.get(`/app/admin/modules/${params.uuid}`).then((res) => {
			setModule(res);
			setSavedModule(_.cloneDeep(res));
		});
	},[]);

	const save = async () => {
		jax.post(`/app/admin/modules/${params.uuid}`, module).then((res) => {
			setEditing(false);
			setHasChanges(false);
		});
	}

	const cancelEditing = () => {
		setEditing(false);
		setModule(savedModule)
		setHasChanges(false);
	};

	useEffect(() => {
		if(module && savedModule){
			setHasChanges(!_.isEqual(module, savedModule));
		}
	},[module, savedModule]);
	
	return <Box p={0}>
		<Box flex={1} p={2}>
			<Breadcrumbs separator={<Box className="sub-title">/</Box>}> 
				<RouterLink className="sub-title" color="text.secondary" to={`/admin/modules`}>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<ArrowBackIos fontSize='inherit'/> 
						<Box>All Modules</Box>
					</Stack>
				</RouterLink> 
			</Breadcrumbs>
			<Stack direction="row" alignItems="flex-start" spacing={4} mt={2}>
				<Box  py={2}>
					<Box flex={1}>
						{!editing && <Stack direction="row" alignItems="center" spacing={1}><h2 >{module ? module.name  : <Skeleton></Skeleton>}</h2><IconButton size="small" onClick={()=>setEditing(true)}><Edit/></IconButton></Stack>}
						{editing && <TextField name="name" size="small" label="Course Type Name" inputProps={{size:61}} defaultValue={module.name} onChange={(e)=>{module.name=e.currentTarget.value; setModule({...module}); }}></TextField>}
					</Box>
					<Box pt={editing ? 2 : 0}>
						<Box className="sub-title">
							{!editing && module?.description}
							{editing && <TextField name="description" multiline rows={2} inputProps={{cols:60}} size="small" label="Description" onChange={(e)=>{module.description=e.currentTarget.value; setModule({...module}); }} defaultValue={module.description}></TextField>}
						</Box>
					</Box>
				</Box>
				{editing && <Stack direction="row" spacing={1} flex={1} py={2}>
					{hasChanges && <Button size="small" variant="contained" color="primary" onClick={save}>Save Changes</Button>}
					{!hasChanges && <Button size="small" variant="contained" color="disabled" disabled>Saved</Button>}
					<Button size="small" variant="outlined" color="disabled" onClick={cancelEditing}>Cancel</Button>
				</Stack>}
				
			</Stack>
		</Box>
			
		{module && <ContentExplorer basePath={`modules/${module.uuid}`} minHeight={300}/>}
				
	</Box>;
}

export default Module;