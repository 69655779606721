import { useSelector } from 'react-redux';
import { roles, roleNames } from '../features/access';
import { Box, Divider, Stack, Button, TextField, Collapse, Checkbox, FormControlLabel, Typography, Chip, Link } from '@mui/material';
import { asDate } from '../helper/util';
import { hasRole } from '../features/access';
import { ToggleButtonGroup } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { useRef, useState } from 'react';
import jax from '../helper/jax';
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot} from '@mui/lab';
import TimelineOppositeContent, {
	timelineOppositeContentClasses
  } from '@mui/lab/TimelineOppositeContent';

  import TimelineContent, {
	timelineContentClasses
  } from '@mui/lab/TimelineContent';
import { useTheme } from '@emotion/react';
import { Assignment, Error, FactCheck, HistoryEdu, Lock, MoreHoriz, MoreTime, Replay, School, Stars, TaskAlt, Update } from '@mui/icons-material';

function TrainingContent(props) {
	const {training, onVerify, onSubmitTraining, onConfirmRefresher, color} = props;
	const {roleMap,user} = useSelector((state)=>state.data);
	const [approved, setApproved] = useState(null);
	const [showComments, setShowComments] = useState(false);
	const [comments, setComments] = useState("");
	const [distinction, setDistinction] = useState(false);
	const [show_details, setShowDetails] = useState(false);
	const [valid, setValid] = useState(null);

	const theme = useTheme();
	const confirm = function() {
		if (comments.trim() == "") {
			setValid(false);
			return;
		}
		setShowComments(false);
		onVerify(training.id, approved, comments, 0);
	}

	const confirmRefresher = function(confirmed) {
		
		onConfirmRefresher(training.id, confirmed);
	}

	var widget = null;

	// 3 = "Refresher Training"
	// 2 = "Prior Training"
	// 1 = "Course Completion"

	if (training.method == 3) { //Refresher Training

		widget = <Stack spacing={1} pb={1}>
		<Typography variant="h6" sx={{color: theme.palette[color].main}}>Refresher Training</Typography>
		{!training.approved && <Box>This refresher training event was recorded by <b>{training.instructor}</b> on <b>{asDate(training.reviewed_on)}</b>, but requires your confirmation before being reflected on your training record. Please confirm this event below if it is accurate.</Box>}
		{training.approved && <Box>Refresher training or equivalent instruction was submitted and confirmed.</Box>}
		<Stack pt={2} pb={1} direction={{xs:"column", lg:"row"}} flex={1} justifyContent="space-between">
			<Box flex={1}>
				<Box className="sub-title xs">LOCATION</Box>
				<Box >{training.location}</Box>
			</Box>
			<Box flex={1}>
				<Box className="sub-title xs">INSTRUCTOR</Box>
				<Box >{training.instructor}</Box>
			</Box>
		</Stack>
		{user.uid != training.user_id && !training.approved && <><Stack direction="row" mt={2} spacing={1} >
			{!!user.verified && <>
				<Button size="small" variant={approved === true ? "contained" : "outlined"} color="success" mr={1} onClick={(e)=>{confirmRefresher(true);} }>Confirm Training</Button>
				<Button size="small" variant={approved === false ? "contained" : "outlined"} color="error" ml={1} onClick={(e)=>{confirmRefresher(false);} }>Remove Training</Button>
			</>}

			{!user.verified && <Stack direction="row" alignItems="center" spacing={1}>
				<Box><Error color="error" fontSize="large"/></Box>
				<Box><Typography color="error"> Your Valkyrie training as <b>{roleNames[user.valk_role_id]}</b> has not been verified.  Until your past training has been submitted and verified, you are unable to confirm this refresher training event.  You can verify your Valkyrie training by <Link className="inherit" onClick={onSubmitTraining}>submitting prior training details</Link>.</Typography>	</Box>
			</Stack>}
		</Stack>
		</>}
	</Stack>;
	} else if (training.method == 2) { //Legacy Training Verification
		if (training.approved == null) {
			widget = <Stack spacing={1} pb={1}>
				<Typography variant="h6" color={theme.palette[color].main} sx={{color:theme.palette.warning.main}}>Unverified {roleNames[training.role_id]} Training</Typography>
				{hasRole(user, roles.ADMIN) ? <Box>This user submitted evidence of prior training that requires approval.  Please verify training based on the information below and approve or reject their Valkyrie status as appropriate.</Box> : <Box>This training is pending review by an administrator</Box>}
				<Stack pt={2} pb={1} direction={{xs:"column", lg:"row"}} flex={1} justifyContent="space-between">
					<Box flex={1}>
						<Box className="sub-title xs">LOCATION</Box>
						<Box >{training.location}</Box>
					</Box>
					<Box flex={4}>
						<Box className="sub-title xs">COMMENTS / EVIDENCE</Box>
						{training.submission_notes && <Box mb={1} >{training.submission_notes}</Box>}
						<Stack direction="row" spacing={1} alignItems="center">
							{training.document1 && <Box className="sub-title"><a target="_new" href={training.document1}>Certificate</a></Box>}
							{training.document2 && <Box className="sub-title"><a target="_new" href={training.document2}>Certificate</a></Box>}
						</Stack>
					</Box>
				</Stack>
				{hasRole(user, roles.ADMIN) && <><Stack direction="row" mt={2} spacing={1} >
					<Button size="small" variant={approved === true ? "contained" : "outlined"} color="success" mr={1} onClick={(e)=>{setApproved(true); setShowComments(true);}}>Verify Training</Button>
					<Button size="small" variant={approved === false ? "contained" : "outlined"} color="error" ml={1} onClick={(e)=>{setApproved(false); setShowComments(true);}}>Disapprove Training</Button>
				</Stack>
				<Box  pt={1} style={{position:"relative", overflow:"hidden", height:"auto"}}>
					<Collapse in={showComments} >
						<Box mt={1}>
							<Box  mb={1}>Please provide a description of evidence used in {approved ? "verifying" : "disapproving"} the training submitted by this user.  These comments will be visible to the user.</Box>
							<Box><TextField multiline rows={2} value={comments} fullWidth  style={{backgroundColor:"white"}} helperText={valid === false && "Required"} error={valid === false} InputProps={{style:{fontSize: "0.9rem", 	padding:'4px 8px'}}} onChange={(e) => setComments(e.currentTarget.value)}/></Box>
							<Stack mt={1} spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
								{false && approved && <FormControlLabel mr={3} size="small" control={<Checkbox checked={distinction} onChange={(e)=>{setDistinction(e.target.checked);}} color="primary" size="small" ></Checkbox>} label="Graduated With Distinction"></FormControlLabel>}
								<Box flex={1}></Box>
								<Button size="small" variant="contained" color={approved  ? 'primary' : 'error'} onClick={confirm}>Confirm</Button>
								<Button size="small" color="disabled"  onClick={(e)=>{setShowComments(false); setApproved(null); setComments("");}}>Cancel</Button>
							</Stack>
						</Box>
					</Collapse>
				</Box></>}
			</Stack>;
		} else if (training.approved == 1) { 
			widget = <Stack flex={1} pb={2} justifyContent="space-between">
				<Typography variant="h6" color={theme.palette[color].main}>Verified {roleNames[training.role_id]} Training</Typography>
				<Box className="sub-title" mb={1}>{training.location}</Box>
				{[2,4].includes(training.role_id) && <Box>Evidence of prior training as <b>Valkyrie {roleNames[training.role_id]}</b> has been verified.</Box>}	
				{!!training.new_role_id &&  <Stack color={theme.palette[color].main} direction="row" mt={1} alignItems="center" spacing={1} className="sub-title">
					<Chip color="success" size="small" label={`Promoted to ${roleMap[training.new_role_id]?.name}`} icon={<TaskAlt/>}/>
					{!!training.distinction && <Chip color="gold" size="small" label="Graduated with distinction" icon={<Stars/>}/>}
					
				</Stack>}
				
				{hasRole(user, roles.ADMIN) && <Box mt={1}><Button size="small" startIcon={<Lock/>} color="primary" onClick={(e)=>{setShowDetails(!show_details);}}>View Details</Button></Box>}
				<Collapse in={show_details}>
					<Box pt={2}>
						<Box className="sub-title">Evidence / Certificate</Box>
						{training.submission_notes}
						{training.document1 && <Box className="sub-title"><a target="_new" href={training.document1}>View Certificate</a></Box>}
						{training.document2 && <Box className="sub-title"><a target="_new" href={training.document2}>View Certificate</a></Box>}

						<Box mt={2} className="sub-title">Reviewed By</Box>
						<Box>{training.reviewed_by} on {asDate(training.reviewed_on)}</Box>
						<Box mt={2} className="sub-title">Reviewer Notes</Box>
						<Box>{training.reviewer_notes}</Box>
					</Box>
				</Collapse>
			</Stack>;
		} else if (training.approved == 0) {
			widget = <Stack spacing={2}>
				<Box>
					<Typography variant="h6" color={theme.palette[color].main}>Disapproved {roleNames[training.role_id]} Training</Typography>
					<Box className="sub-title">{training.location}</Box>
				</Box>
				<Box>Insufficient or unverifiable evidence of training was provided.  Please see reviewer comments below for more information.</Box>
				<Box>
					<Box className="sub-title">EVIDENCE / CERTIFICATE</Box>
					<Box >{training.submission_notes}</Box>
					{training.document1 && <Box className="sub-title"><a target="_new" href={training.document1}>View Certificate</a></Box>}
					{training.document2 && <Box className="sub-title"><a target="_new" href={training.document2}>View Certificate</a></Box>}
				</Box>

				<Box>
					<Box className="sub-title">REVIEWER COMMENTS</Box>
					<Box >{training.reviewer_notes}</Box>
				</Box>

				{hasRole(user, roles.ADMIN) && <Box mt={1}><Button size="small" startIcon={<Lock/>} color="primary" onClick={(e)=>{setShowDetails(!show_details);}}>View Details</Button></Box>}
				<Collapse in={show_details}>
					<Box >
						<Box className="sub-title">Reviewed By</Box>
						<Box>{training.reviewed_by} on {asDate(training.reviewed_on)}</Box>
					</Box>
				</Collapse>	
			</Stack>
		}
	} else if (training.method == 1) { //In-Sytem Course Training
		widget = <Stack flex={1} pb={2} justifyContent="space-between">
			<Typography variant="h6" color={theme.palette[color].main}>{training.name} </Typography>
			<Box className="sub-title" mb={1}>{training.program_name}</Box>
			
			{!!training.graduated && <Box>Completed this course as <b>{roleMap[training.role_id]?.name}</b></Box>}
			{!training.graduated && !training.withdrew && <Box>Attended this course as <b>{roleMap[training.role_id]?.name}</b>.</Box>}
			

			
			{!training.graduated && <Stack direction="row" mt={1} alignItems="center" spacing={1} className="sub-title">
				<Chip size="small" label="More training was required" icon={<MoreTime/>}/>
			</Stack>}
			{!!training.graduated &&  <Stack color={theme.palette[color].main} direction="row" mt={1} alignItems="center" spacing={1} className="sub-title">
				
				<Chip color="success" size="small" label={`Promoted to ${roleMap[training.new_role_id]?.name}`} icon={<TaskAlt/>}/>
				
			</Stack>}

			
		</Stack>;
	}

	return <>{widget}</>;
}

export default function TrainingHistory(props) {

	const me = useSelector((state) => state.data.user);
	const {records, user_id, onTrainingUpdate, onSubmitTraining} = props;
	const theme = useTheme();
	const {user} = useSelector((state) => state.data);
	const onVerify = async function(id, approved, comments, distinction) {
		
		var result = await jax.post(`/app/admin/training/${approved ? 'verify' : 'disapprove'}/${user_id}/${id}`, {
			notes: comments,
			distinction: !!distinction
		});
		
		onTrainingUpdate(result.training, result.profile);
	}

	const onConfirmRefresher = async function(id, confirmed) {
		debugger;

		if (confirmed) {
			var result = await jax.post(`/app/training/refresher/acknowledge/${id}`);
			onTrainingUpdate(result.training, result.profile);
		} else {
			var result = await jax.delete(`/app/training/refresher/${id}`);
			onTrainingUpdate(result.training, result.profile);
		}
	}

	var filtered = records.filter(
		(t)=>{
			if (t.method == 3 && t.approved === 0 && user_id != me.uid) {
				return false;
			}
			return t.approved || (me.uid === user_id) || hasRole(me, roles.ADMIN)
		});	

	return <Stack fontSize="0.9rem">
	
	
	<Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
	{!!filtered?.length ? filtered.map((t, i)=>{
		var color = "black";
		var icon = <School/>;

		if (t.method == 3) {
			if (t.approved) {
				color = "success";
			} else {
				if (user.verified) {
					color = "warning";
				} else {
					color = "error";
				}
			}
			icon = <Update/>;
		} else if (t.method == 2) {
			if (t.approved == 0) {
				color = "error";
			} else if (t.approved == 1) {
				color = "success";
			} else {
				color = "warning";
			}
			icon = <FactCheck/>;
		} else if (t.method == 1) {
			if (t.role_id == roles.INSTRUCTOR){
				color = "disabled";
				icon = <Assignment/>;
			} else if (t.graduated === 1) {
				color = "success";
				icon = <School/>;
			} else {
				color = "disabled";
				icon = <MoreHoriz/>;
			}
			
		}

		// if (t.approved === 0) {
		// 	color = "error";
		// } else if (!t.approved) {
		// 	color = "warning";
		// } else if (!t.graduated) {
		// 	color = "disabled";
		// } else if (t.graduated) {
		// 	color = "success"
		// }

		return <TimelineItem key={i}>
			<TimelineOppositeContent sx={{display: {xs:'none', lg: 'block'}}} color="textSecondary">
				{asDate(t.start_date)}
			</TimelineOppositeContent>
			<TimelineSeparator>
				<TimelineDot color={color} sx={{marginTop:"3px", "& svg": {fontSize: 18}}}>{icon}</TimelineDot>
				{i < filtered?.length-1 && <TimelineConnector />}
			</TimelineSeparator>
			<TimelineContent sx={{paddingTop: "2px !important"}}>
				<Box pt={0.5} sx={{display: {xs:'', lg: 'none'}}}>{asDate(t.start_date)}</Box>
				<TrainingContent color={color} key={i} training={t} onVerify={onVerify} onSubmitTraining={onSubmitTraining} onConfirmRefresher={onConfirmRefresher}></TrainingContent>
			</TimelineContent>

		</TimelineItem>
	}) : <Box p={4}><i>No known training history is available</i></Box>}
	</Timeline>
	</Stack>

}