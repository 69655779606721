import { createSlice } from '@reduxjs/toolkit'
import { useSelector, createSelector } from 'react-redux'

export const dataSlice = createSlice({
	name: 'app',
	initialState: {
		page_title: "...",
		crumbs: [],
		is_mobile: false,
		show_scorer: false,
		show_profile: null,
		show_feedback: false,
		system: null,
		tenantInfo: null
	},
	reducers: {
		setPageTitle: (state, action) => {
			state.page_title = action.payload
		},
		setCrumbs: (state, action) => {
			state.crumbs = action.payload
		},
		setIsMobile: (state, action) => {
			state.is_mobile = action.payload
		},
		setShowProfile: (state, action) =>{
			state.show_profile = action.payload
		},
		setShowFeedback: (state, action) =>{
			state.show_feedback = action.payload
		},
		setSystem: (state, action) =>{
			state.system = action.payload
		},
		setTenantInfo: (state, action) =>{
			state.tenantInfo = action.payload
		}
	},
})

export const { setPageTitle, setCrumbs, setIsMobile, setShowScorer, setShowProfile, setShowFeedback, setSystem, setTenantInfo } = dataSlice.actions;
export default dataSlice.reducer