import { Dialog, Select, useMediaQuery, InputLabel, FormControl, MenuItem, DialogActions } from "@mui/material";
import { Box} from "@mui/system";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import jax from "../helper/jax";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";




export default function NewCourse(props) {
	const {open, onCancel, program_id} = props;

	const nav = useNavigate();

	const create = async function(data) {
		try {
			var result = await jax.put('/app/courses', data);
			if (result.code) {
				nav(`/courses/${result.code}`);
				return;
			}
			if (props.onComplete) {
				props.onCreate(result.code);
			}
		} catch (err) {
			debugger;
		}
	}

	const user = useSelector((state) => state.data.user);
	const syllabi = useSelector((state) => state.data.syllabi);
	const [course_types, setCourseTypes] = useState([]);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

	const {control , handleSubmit, formState: { errors }} =  useForm({
		defaultValues: {
			name: '',
			start_date: '',
			course_type_id: '',
			program_id: '',
			expected_end_date: ''
		}
	});

	useEffect(()=>{
		if (program_id) {
			control.setValue('program_id', program_id);
		}
		jax.get('/app/admin/course_types').then((response)=>{
			setCourseTypes(response);
		});
	}, []);

	return <Dialog open={open} fullScreen={isMobile} >
		<Box className="card-header">
				New Course
			</Box>
		<Box p={3}>
		<form onSubmit={handleSubmit(create)} noValidate>
			
			<Box minWidth={isMobile ? 0 : 400}>
			
				<Stack spacing={3}>
					<FormControl size="small">
						<InputLabel id="program-label">Program</InputLabel>
						<Controller name="program_id" control={control} rules={{required: 'Required'}} render={({ field }) =>
							<Select label="Program" size="small" labelId="program-label" id="program" {...field}  error={!!errors.program_id}>
								{user.programs.map((p,i)=><MenuItem  key={i} value={p.id}>{p.name}</MenuItem>)}
							</Select>
						}/>
					</FormControl>
					<Controller name="name" control={control} rules={{required: 'Required'}} render={({ field }) =>
						<TextField label="Course Name" size="small" required {...field} error={!!errors.name}></TextField>
					}/>
					<FormControl size="small">
						<InputLabel id="type-label">Course Type</InputLabel>
						<Controller name="course_type_id" control={control} rules={{required: 'Required'}} render={({ field }) =>
							<Select label="CourseType" size="small" required labelId="type-label" id="course_type" {...field} error={!!errors.course_type_id} >
								{course_types.map((s,i)=><MenuItem  key={i} value={s.id}>{s.name}</MenuItem>)}
							</Select>
						}/>
					</FormControl>
					<Stack direction={{xs: "column", lg: "row"}} spacing={2}>
						<Controller name="start_date" control={control} rules={{required: 'Required'}} render={({ field }) =>
							<TextField fullWidth label="Start Date" size="small" type="date" required  error={!!errors.start_date} {...field} InputLabelProps={{ shrink: true }}></TextField>
						}/>
						<Controller name="expected_end_date" control={control} rules={{required: 'Required'}} render={({ field }) =>
							<TextField fullWidth label="Expected Completion" type="date" required size="small" error={!!errors.expected_end_date} {...field} InputLabelProps={{ shrink: true }} ></TextField>
						}/>
					</Stack>
					<Box pt={0}>
						<Controller name="instructions" control={control} render={({ field }) => <TextField fullWidth label="Notes / Instructions" size="small" multiline rows={4} {...field} ></TextField>}/>
					</Box>
					
				</Stack>
			
			</Box>
			
			<Stack justifyContent="center" direction="row" mt={3} spacing={2} flex={1}>
				<Button variant="contained" disabled={!course_types} color="primary" type="submit" text="">Save Changes</Button>
				<Button variant="outlined" onClick={(e)=>{e.preventDefault(); onCancel()}} text="">Cancel</Button>
			</Stack>
			
		</form>
		</Box>
	</Dialog>;
}