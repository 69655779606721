import { Box, Breadcrumbs, Button, Chip, Checkbox, FormControlLabel, ListItemText, Menu, MenuItem, Skeleton, Stack, Paper, Divider, FormControl, Select, InputLabel, TextField, IconButton, Accordion, AccordionSummary, AccordionDetails, Card, alpha, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import jax from '../helper/jax';
import { useParams } from 'react-router-dom';
import { Add, ArrowBackIos, ArrowRight, Cancel, CheckCircle, ChevronRight, Delete, DragHandle, Edit } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import {NestedMenuItem} from 'mui-nested-menu';
import { styled, useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { SortableItem, SortableList } from '../components/SortableList.tsx';
import Notification from '../components/Notification.js';
import { roles as roleTypes } from '../features/access.js';


interface CourseTypeProps {
	// Define the props for your component here
}

const CourseType: React.FC<CourseTypeProps> = (props) => {
	const {id} = useParams();
	const [type, setType] = useState<any>();

	const [schemas, setSchemas] = useState<any>(null);
	const [evals, setEvals] = useState<any>({});
	const [modules, setModules] = useState<any>([]);
	const [evalId, setEvalId] = useState<number | null>(null);
	const [savedType, setSavedType] = useState<any | null>(null);
	const [hasChanges, setHasChanges] = useState<boolean>(false);
	const [editing, setEditing] = useState<boolean>(false);
	const roles = useSelector((state: any) => state.data.roles);
	const theme = useTheme();

	

	useEffect(() => {
		if (id) {
			jax.get(`/app/admin/course_types/${id}`).then((res) => {
				setType(res || {});
			});

			jax.get('/app/evaluations?published=1').then((res) => {
				setEvals(res.evals || {});
			});

			jax.get('/app/admin/modules').then((res) => {
				setModules(res.modules || []);
			});
		}
	}, []);

	useEffect(() => {
		if (evalId) {
			setSchemas(evals[evalId].versions || {});
		}
	}, [evalId]);

	useEffect(() => {
		if (type && !savedType) {
			setSavedType(_.cloneDeep(type));
		} else if (type && savedType) {
			setHasChanges(!_.isEqual(type, savedType));
		}
	}, [type, savedType]);
	
	useEffect(() => {

	}, [savedType]);
	

	const toggleRole = (role_id: any) => {
		var role = type.roles.find((r: any) => r.role_id == role_id);
		if (role) {
			role.remove = !role.remove || false;
			setType({...type});
		} else {
			type.roles = [...type.roles, {role_id: role_id, evals: []}];
			setType({...type});
		}
	}

	const updateRole = (role : any) => {
		var roles = [...type.roles];
		var r = roles.find((r: any) => r.role_id == role.role_id);
		if (r) {
			r = {...role};
			setType({...type, roles: roles});
		}
	}

	const save = () => {
		jax.post(`/app/admin/course_types/${id}`, type).then((res) => {
			setType(res || {});
			setSavedType(_.cloneDeep(res));
			setEditing(false);
		});
	}

	return <Box >
		<Stack direction="row" mb={0} px={2} py={1} flex={1} justifyContent="space-between" alignItems="center" sx={{position:'sticky', top:0, zIndex:1000, backgroundColor: !hasChanges ? theme.palette.background.paper : theme.palette.primary["pale"], borderBottom: `solid 1px ${theme.palette.divider}`}}>
			<Breadcrumbs separator={<Box className="sub-title">/</Box>}> 
				<RouterLink className="sub-title" color="text.secondary" to={`/admin/course_types`}>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<ArrowBackIos fontSize='inherit'/> 
						<Box>All Course Types</Box>
					</Stack>
				</RouterLink> 
			</Breadcrumbs>

			<Stack direction="row" spacing={2}>
				{(editing || hasChanges) && <Button size="small" variant="outlined" color="disabled" onClick={()=>{setType(_.cloneDeep(savedType)); setEditing(false); setHasChanges(false)}}>Cancel</Button>}
				{hasChanges && <Button size="small" variant="contained" color="primary" onClick={save}>Save Changes</Button>}
				{!hasChanges && <Button size="small" variant="contained" color="disabled" disabled>Saved</Button>}
			</Stack>
		</Stack>
		
		<Box flex={1} px={2}>
			
			<Box flex={1} py={2}>
				<Box flex={1}>
					{!editing && <Stack direction="row" alignItems="center" spacing={1}><h2 >{type ? type.name  : <Skeleton></Skeleton>}</h2><IconButton size="small" onClick={()=>setEditing(true)}><Edit/></IconButton></Stack>}
					{editing && <TextField name="name" size="small" label="Course Type Name" inputProps={{size:60}} defaultValue={type.name} onChange={(e)=>{type.name=e.currentTarget.value; setType({...type}); }}></TextField>}
				</Box>
				<Box pt={editing ? 2 : 0}>
					<Box className="sub-title">
						{!editing && type?.description}
						{editing && <TextField name="description" multiline rows={2} inputProps={{cols:60}} size="small" label="Description" onChange={(e)=>{type.description=e.currentTarget.value; setType({...type}); }} defaultValue={type.description}></TextField>}
					</Box>
				</Box>
			</Box>	
			
			<Divider/>
			<Stack spacing={2} mt={1}>
				
				<Box py={2}>
					<h3>Enrollment Roles</h3>
					<Box className="sub-title" mt={1}>
						Allow the following role(s) to enroll in courses of this type.  Each role may be assigned one or more exit evaluations that may be required for course completion.
					</Box>
					<Stack spacing={2} mt={2} >
						{roles.filter(r=>!r.base_role).map((role: any, i : number) => {
							var existing = type?.roles?.find(r=>r.role_id==role.id && !r.remove);
							
							return <CourseRole key={i} selected={!!existing} role_id={role.id} role_name={role.name} role={!!existing && existing} onSelect={toggleRole} onChange={updateRole} evals={evals} modules={modules}/>
						})}
					</Stack>
				</Box>
			</Stack>
		</Box>
	</Box>;
};

const CourseRole = (props: any) => {
	const {role_id, role_name, selected, onSelect, evals, modules, role, onChange} = props;

	const [evalMenuEl, setEvalMenuEl] = useState<null | HTMLElement>(null);
	const [moduleMenuEl, setModuleMenuEl] = useState<null | HTMLElement>(null);
	const [schemaMenuEl, setSchemaMenuEl] = useState<null | HTMLElement>(null);
	const [shouldAdvance, setShouldAdvance] = useState<boolean>(!!role.completion_role_id);
	const [shouldCertify, setShouldCertify] = useState<boolean>(false);
	const [assignRole, setAssignRole] = useState<any>(role.completion_role_id || '');
	const [showNewOutcome, setShowNewOutcome] = useState<any>(null);
	const [newOutcomeName, setNewOutcomeName] = useState<any>('');
	const [showOutcome, setShowOutcome] = useState<number | null>(null);
	const [awardCert, setAwardCert] = useState<any>('');

	const {roles, roleMap} = useSelector((state: any) => state.data);

	let theme = useTheme();
	
	const addRoleEval = (role_id: any, eval_type_id: number, schema_id: number, at_enrollment: any) => {
		
		if (role) {
			var evl = evals[eval_type_id];
			var schema = evl.versions.find((v: any) => v.id == schema_id);
			if (!role.evals) {
				role.evals = [];
			}
			role.evals.push({schema_id: schema_id, name: `${evl.name} (V${schema.version})`, sequence: role.evals.length+1, at_enrollment: parseInt(at_enrollment?.toString())});
			role.evals.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	function addModule(module_id: any) {
		if (role) {
			var module = modules.find(m=>m.id==module_id);
			if (!role.modules) {
				role.modules = [];
			}
			
			role.modules.push({module_id:module_id, name: module.name, sequence: role.modules.length+1});
			role.modules.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	}

	const removeRoleEval = (schema_id: number | string, at_enrollment: number) => {
		if (role) {
			role.evals = role.evals.filter((e: any) => e.schema_id != schema_id || e.at_enrollment != at_enrollment);
			role.evals.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	const removeRoleModule = (module_id: number | string) => {
		if (role) {
			role.modules = role.modules.filter((e: any) => e.module_id != module_id);
			role.modules.map((e: any, i: number) => {
				e.sequence = i+1;	
			});
			onChange(role);
		}
	};

	function reorder_module(action) {
		let mods = role.modules.sort((a,b) => a.sequence < b.sequence);
		let [removed] = mods.splice(action.oldIndex, 1);
		mods.splice(action.newIndex, 0, removed);
		mods.map((e: any, i: number) => {
			e.sequence = i+1;
		});
		onChange(role);
	}

	function reorder_eval(action) {
		let evls = role.evals.sort((a,b) => a.sequence < b.sequence);
		let [removed] = evls.splice(action.oldIndex, 1);
		evls.splice(action.newIndex, 0, removed);
		evls.map((e: any, i: number) => {
			e.sequence = i+1;
		});
		onChange(role);
	}

	function createOutcome(e) {
		e.preventDefault();
		if (role) {
			role.outcomes.push({name: newOutcomeName, sequence: role.outcomes.length+1, role_id: role.role_id, new_role_id: null, award_cert: null});
			onChange(role);
			setShowNewOutcome(null);
			setNewOutcomeName('');
			setShowOutcome(role.outcomes.length-1);
		}
	}

	useEffect(() => {
		if (role){
			var ri = roleMap[role_id];
			setAssignRole(role.completion_role_id);
			setShouldAdvance(!!role.completion_role_id);
		}
	}, [role]);

	useEffect(() => {
		if (shouldAdvance) {
			if (!!assignRole && role.completion_role_id != assignRole) {
				role.completion_role_id = assignRole;
				onChange(role);
			}
		} else {
			if (!!role.completion_role_id) {
				role.completion_role_id = null;
				onChange(role);
			}
		}
		
	}, [assignRole, shouldAdvance]);

	return <Paper variant={selected ? "outlined" : "elevation"} sx={{backgroundColor: selected ? '' : theme.palette['disabled']['pale'], color: !selected ? theme.palette['disabled']['main'] :'' }} elevation={0}>
		<Stack spacing={4}>
			<Stack  alignItems="stretch">
				<Stack px={1.5} direction="row" justifyContent="flex-start">
					<FormControlLabel  slotProps={{typography: {fontWeight: selected ? 'bold' : ''}}} control={<Checkbox checked={selected} onChange={()=>onSelect(role_id)}/>} label={role_name} />
					{selected && roleMap[role_id].root_role == roleTypes.INSTRUCTOR && <FormControlLabel sx={{ml:3}} control={<Checkbox checked={!!role.can_instruct} value={1} onChange={(e)=>{role.can_instruct=e.currentTarget.checked; onChange(role);}}/>} label="Allow enrollment as Course Instructor" />}
				</Stack>
				{selected && <Divider />}
				{role && <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-evenly">
					<Stack flex={1} direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={0}>
						<Box flex={1} padding={2}>
							<Box height={80}>
								<b>Training Module(s)</b>
								<Box className="sub-title" mt={1}>
									Select which module(s) participants in this role will complete during this course.
								</Box>
							</Box>

							<Box mt="46px" pb={1}>
								<SortableList onSortEnd={reorder_module}  allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={removeRoleModule}>
									{role.modules && role.modules?.sort((a,b) => a.sequence < b.sequence).map((mod: any, j: number) => {
										return <SortableItem key={j} index={j} text={mod.name} id={mod.module_id}/>
									})}
									
								</SortableList>
							</Box>

							<Box data-role-id={role.id} ><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setModuleMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.role_id}  >Add Module</Button></Box>

						</Box>
						<Divider orientation="vertical" flexItem variant="middle"/>
						<Box flex={1} padding={2}>
							<Box height={80}>
								<b>Evaluation(s)</b>
								<Box className="sub-title" mt={1}>
									Present students of this role with the following evaluation(s) at the end of the course.
								</Box>
							</Box>
							<Stack pt={1} mt={2} spacing={0.5} justifyContent="stretch">
								<b>At Enrollment</b>
								<Box mt={3} pb={1}>
									<SortableList onSortEnd={reorder_eval} allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={(id)=>removeRoleEval(id, 1)}>
										{role.evals && role.evals?.filter(x=>!!x.at_enrollment).sort((a,b) => a.sequence < b.sequence).map((evl: any, j: number) => {
											return <SortableItem key={j} index={j} text={evl.name} id={evl.schema_id}/>
										})}
									</SortableList>
								</Box>
								
								<Box data-role-id={role.id} data-at-enrollment={1}><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setEvalMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.role_id} data-at-enrollment={1} >Add Evaluation</Button></Box>
							</Stack>
							<Stack pt={1} mt={2} spacing={0.5} justifyContent="stretch">
								<b>Upon Completion</b>
								<Box mt={3} pb={1}>
									<SortableList onSortEnd={reorder_eval} allowDrag={true} allowDelete={true} useDragHandle lockAxis='y' onDelete={(id)=>removeRoleEval(id, 0)}>
										{role.evals && role.evals?.filter(x=>!x.at_enrollment).sort((a,b) => a.sequence < b.sequence).map((evl: any, j: number) => {
											return <SortableItem key={j} index={j} text={evl.name} id={evl.schema_id}/>
										})}
									</SortableList>
								</Box>
								

								<Box data-role-id={role.id} data-at-enrollment={0}><Button color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setEvalMenuEl(e.currentTarget)}} sx={{ ml:0}} size="small" data-role-id={role.role_id} data-at-enrollment={0} >Add Evaluation</Button></Box>

								
							</Stack>
							<Box className="sub-title xs" pt={2} fontStyle="italic">
								If multiple evaluations are selected, they will be combined and presented as one seamless evaluation to the student.
							</Box>
						</Box>
						<Divider orientation="vertical" flexItem variant="middle"/>
						<Stack flex={1} padding={2} spacing={0}>
							<Box height={80}>
								<b>Completion Statuses</b>
								<Box className="sub-title" mt={1}>
									At the completion of each course, each {role.name} will be assigned a completion status based on their performance.
								</Box>
							</Box>

							<Card  variant="outlined" sx={{borderColor: alpha(theme.palette.primary.main,0.5), mt:'46px'}}>
								{role.outcomes.map((o: any, i: number) => {
									return <React.Fragment key={i}>
										<OutcomeAccordion key={i} expanded={showOutcome == i} onChange={()=>showOutcome == i ? setShowOutcome(null) : setShowOutcome(i)}>
											<AccordionSummary expandIcon={<ChevronRight color="primary"/>}>
												<Stack direction="row" flex={1} alignItems="center" >
													<Box flex={1}>{o.name}</Box>
													<Box pr={1} lineHeight={0}>
														{o.pass === 1 && <CheckCircle fontSize="small" color="success"/>}
														{o.pass === -1 && <Cancel fontSize="small" color="error"/>} 
													</Box>

												</Stack>
											</AccordionSummary>
											<AccordionDetails>
												<RoleOutcome role={role} roles={roles} outcome={o} onChange={(role)=>onChange(role)}/>
											</AccordionDetails>

										</OutcomeAccordion>
										<Box borderTop={`solid 1px ${alpha(theme.palette.primary.main,0.5)}`}></Box>
									</React.Fragment>
								})}
								<Box>
								<Button fullWidth color="primary" variant="text" startIcon={<Add/>} onClick={(e)=>{setShowNewOutcome(e.currentTarget)}} sx={{ ml:0, my:"-1px"}} size="medium" data-role-id={role.role_id} data-at-enrollment={1} >Add Completion Status</Button>
								<Notification open={!!showNewOutcome} anchorEl={showNewOutcome} onClose={()=>setShowNewOutcome(null)} minWidth={400} maxWidth={600} background="#fff" color="#000" over={true} ContainerProps={{p:0, overflow: 'hidden'}}>
									<form onSubmit={createOutcome}>
										<Box className="card-header">Add a Completion Status</Box>
										<Stack p={2} spacing={2}>
											<Box><TextField fullWidth size="small" autoFocus label="New Outcome Name" value={newOutcomeName} onChange={(e)=>setNewOutcomeName(e.currentTarget.value)}/></Box>
											<Stack direction="row" spacing={1} justifyContent="flex-end">
												<Button size="small" variant="outlined" color="primary" onClick={()=>setShowNewOutcome(null)}>Cancel</Button>
												<Button size="small" variant="contained" type="submit" color="primary" >Create Outcome</Button>
											</Stack>
										</Stack>
									</form>
								</Notification>
								</Box>
							</Card>
							
							
						</Stack>				
						
					</Stack>
				</Stack>}
			</Stack>

			<Menu
				id="eval-menu"
				anchorEl={evalMenuEl}
				open={!!evalMenuEl}
				anchorPosition={{top: 0, left: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				onClose={()=>{setEvalMenuEl(null)}}>
					{Object.keys(evals || {}).map((k: any, i: number) => {
						var e = evals[k];

						return <NestedMenuItem key={i} parentMenuOpen={!!evalMenuEl} label={e.name} sx={{alignItems:'center'}} >
							{e.versions?.map((s: any, j: number) => {
								return <MenuItem key={j} onClick={()=>{ addRoleEval(evalMenuEl?.getAttribute('data-role-id'), e.id, s.id, evalMenuEl?.getAttribute('data-at-enrollment')); setEvalMenuEl(null);}}>
									Version {s.version}
								</MenuItem>
							})}
						</NestedMenuItem>;

						// return <MenuItem onClick={()=>{}} onMouseEnter={(ev)=>{if (e.id != evalId) { setSchemaMenuEl(ev.currentTarget); setEvalId(e.id);}}} sx={{alignItems:'center'}} data-eval-id={e.id} >
						// 	<ListItemText>{e.name}</ListItemText>
						// 	<Box ml={2} lineHeight={0}><ArrowRight/></Box>
						// </MenuItem>
					})}
			</Menu>

			<Menu
				id="module-menu"
				anchorEl={moduleMenuEl}
				open={!!moduleMenuEl}
				anchorPosition={{top: 0, left: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				onClose={()=>{setModuleMenuEl(null)}}>
					{modules.map((m: any, i: number) => {
						return <MenuItem key={i} onClick={()=>{ addModule(m.id); setModuleMenuEl(null);}}>
							{m.name}
						</MenuItem>
						

						// return <MenuItem onClick={()=>{}} onMouseEnter={(ev)=>{if (e.id != evalId) { setSchemaMenuEl(ev.currentTarget); setEvalId(e.id);}}} sx={{alignItems:'center'}} data-eval-id={e.id} >
						// 	<ListItemText>{e.name}</ListItemText>
						// 	<Box ml={2} lineHeight={0}><ArrowRight/></Box>
						// </MenuItem>
					})}
			</Menu>
		</Stack>
	</Paper>;
}

const RoleOutcome = (props: any) => {

	const {role, outcome, roles, onChange} = props;
	const [shouldAdvance, setShouldAdvance] = useState<boolean>(!!outcome.new_role_id);
	const [shouldCertify, setShouldCertify] = useState<boolean>(!!outcome.award_cert);
	const {certs} = useSelector((state: any) => state.data);
	useEffect(() => {
		if (!shouldAdvance) {
			outcome.new_role_id = null;
			onChange(role);
		}
	}, [shouldAdvance]);

	useEffect(() => {
		if (!shouldCertify) {
			outcome.certificate_id = null;
			onChange(role);
		}
	}, [shouldCertify]);

	return <>
		<Stack spacing={0}  alignItems="stretch">
			<Box mb={2}>
				<FormControl size="small" fullWidth >
					<InputLabel>Equivalence</InputLabel>
					<Select label="Equivalence" placeholder='None / Incomplete' value={outcome.pass} onChange={(e)=>{outcome.pass = e.target.value; onChange(role)}}>
						<MenuItem value={1}>Successful Completion</MenuItem>
						<MenuItem value={0}>None / Incomplete</MenuItem>
						<MenuItem value={-1}>Failure to Complete</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<FormControlLabel disabled={outcome.pass < 1} control={<Checkbox checked={shouldAdvance} onChange={(e)=>setShouldAdvance(e.target.checked)}/>} label="Assign User a new Role"/>
			{shouldAdvance && <Box px={4} flex={1} mb={2}>
				<FormControl size="small" fullWidth>
					<InputLabel >New Role</InputLabel>
					<Select label="New Role" defaultValue={outcome.new_role_id || ""} disabled={outcome.pass < 1} onChange={(e)=>{outcome.new_role_id = e.target.value; onChange(role)}}>
						{roles.filter(r=>!r.base_role).map((r: any, i: number) => {
							return <MenuItem key={i} value={r.id}>{r.name}</MenuItem>
						})}
					</Select>
					<FormControlLabel disabled={outcome.pass < 1} control={<Checkbox disabled={!shouldAdvance || !outcome.new_role_id || outcome.pass < 1} checked={outcome.replace_role} onChange={(e)=>{outcome.replace_role=e.currentTarget.checked; onChange(role)}}/>} label={`Replaces ${role.name} Role`}/>
				</FormControl>
			</Box>}

			<FormControlLabel disabled={outcome.pass < 1} control={<Checkbox checked={shouldCertify} onChange={(e)=>setShouldCertify(e.target.checked)}/>} label="Award a Certification"/>
			{shouldCertify && <Box px={4} flex={1} mb={2}>
				<FormControl size="small" fullWidth>
					<InputLabel disabled={outcome.pass < 1}>Certification</InputLabel>
					<Select label="Certification" disabled={outcome.pass < 1} onChange={(e)=>{outcome.certificate_id=e.target.value; onChange(role)}}>
						{certs.map((c: any, i: number) => {
							return <MenuItem key={i} value={c.id}>{c.name}</MenuItem>
						})}
					</Select>
				</FormControl>
			</Box>}
		</Stack>
		
	</>
}

const OutcomeAccordion = styled(Accordion)(({ theme }) => ({
	
	'&.MuiAccordion-root': {
		boxShadow: 'none',
		border: `none`,
		'&.Mui-expanded': {
			margin: 0,
		}
	},
	'.MuiAccordionSummary-content': {
		margin: '0 !important',
		paddingTop: '5px',
		paddingBottom: '5px',
		lineHeight: 1.75
	},
	'.MuiAccordionSummary-root': {
		padding: 0,
		flexDirection: 'row-reverse',
		borderBottom: `none`,
		minHeight: '0 !important',
		fontSize: '0.875rem',
		fontWeight: 600,
		textTransform: 'uppercase',
		color: theme.palette.primary.main,
		'&.Mui-expanded': {
			minHeight: 0,
		}

	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
}));

export default CourseType;