import { Alert, Box, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Link, List, ListItem, ListItemButton, OutlinedInput, Radio, RadioGroup, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { asDate, copyToClipboard } from '../helper/util';
import { Add, Cancel, Delete, Remove } from '@mui/icons-material';

import { getSearchClient } from '../helper/search';
import UserAvatar from './UserAvatar';

import jax from '../helper/jax';
import { hasRole, roles } from '../features/access';
import { updateLocalUser } from '../features/data';

interface LogRefreshTrainingProps {
	open: boolean;
	onClose: () => void;
}

interface Participant {
	mil_id: string;
	title: string;
	unit_name: string;
	unit_location: string;
	agency_id: string;
}

const LogRefreshTraining: React.FC<LogRefreshTrainingProps> = (props) => {
	const {open, onClose} = props;
	const {user} = useSelector((state: any) => state.data); 
	const {isMobile} = useSelector((state: any) => state.app);
	const [milId, setMilId] = React.useState<string>('');
	const [participants, setParticipants] = React.useState<any>([]);
	const [instructor, setInstructor] = React.useState<any>(null);
	const [milIdError, setMilIdError] = React.useState<string | null>(null);
	const [generalError, setGeneralError] = React.useState<string | null>(null);
	const [copyConfirm, setCopyConfirm] = React.useState<boolean>(false);
	var dispatch = useDispatch();

	const {config} = useSelector((state: any) => state.data);
	const tenantInfo = useSelector((state: any) => state.app.tenantInfo);
	const search_client = getSearchClient(tenantInfo?.sk);
	const index = search_client.initIndex(config.personnel_index);

	const {handleSubmit, reset, watch, register, setValue, getValues, control, formState: {errors}} = useForm({
		defaultValues: {
			date: asDate(new Date().toISOString(), 'YYYY-MM-DD'), 
			location: '',
			include_instructor: false,
			training_role: hasRole(user, roles.INSTRUCTOR) ? roles.INSTRUCTOR : roles.GRADUATE
		}
	});

	var wRole = watch('training_role');

	const close = () => {
		setGeneralError(null);
		reset();
		setParticipants([]);
		setInstructor(null);
		setMilId('');
		setMilIdError(null);
		onClose();
	}

	useEffect(() => {
		if (open) {
			reset();
			setParticipants([]);
			setInstructor(null);
			setMilId('');
			setMilIdError(null);
			reset({training_role: hasRole(user, roles.INSTRUCTOR) ? roles.INSTRUCTOR : roles.GRADUATE});
		}
	}, [open]);

	const submit = async (values) => {
		setMilIdError(null);
		if (wRole == roles.INSTRUCTOR && !participants.length) {
			setGeneralError('Please add at least one participant');
			return;
		}
		if (wRole == roles.GRADUATE && !instructor) {
			setGeneralError('Please provide instructor information to continue');
			return;
		}

		var data = {
			date: values.date,
			location: values.location,
			participants: wRole == roles.INSTRUCTOR ? participants : [user],
			instructor_id: wRole == roles.INSTRUCTOR ? user.mil_id : instructor.mil_id,
			training_role: wRole,
			include_instructor: wRole == roles.INSTRUCTOR && values.include_instructor
		};

		try {
			const result = await jax.post('/app/training/refresher', data);
			dispatch(updateLocalUser({last_refresh: values.date}));
			close();
		} catch (err) {
			debugger;
		}
	};

	const addParticipant = async (e) => {	
		e.preventDefault();
		setGeneralError(null);
		if (milId?.trim() == '') {
			setMilIdError('Please enter a DODID / EDIPI');
			return;
		}
		if (milId?.trim() == user.mil_id) {
			setMilIdError('Please specify a participant other than yourself');
			return;
		}
		if (participants.find((p: any) => p.mil_id == milId?.trim())) {
			setMilIdError('Participant already added');
			return;
		}
		if (participants.find((p: any) => user.milId == milId?.trim())) {
			setMilIdError('Unable to add yourself.  Use the checkbox below to receive credit on your training record.');
			return;
		}
		
		const result = await index.search<Participant>(milId, {attributesToRetrieve: ['title', 'unit_name', 'mil_id', 'unit_location', 'agency_id', 'photo'], restrictSearchableAttributes: ['mil_id']});
		
		if(result.hits.length) {
			var hit = {...result.hits[0]};
			hit.title += ` (${milId})`;
			setParticipants([...participants, hit]);
		} else {
			setParticipants([...participants, {mil_id: milId?.trim(), title: `DODID / EDIPI: ${milId?.trim()}`, unit_name: 'Unregistered personnel.  Credit will be awarded upon registration'}]);
		}
		setMilId('');
		setMilIdError(null);
		e.target.value=""; 
	}

	const addInstructor = async (e) => {
		e.preventDefault();
		setGeneralError(null);
		if (milId?.trim() == '') {
			setMilIdError('Please enter a DODID / EDIPI');
			return;
		}

		if (milId?.trim() == user.mil_id) {
			setMilIdError('Please specify an instructor other than yourself');
			return;
		}
		
		const result = await index.search<Participant>(milId, {attributesToRetrieve: ['title', 'unit_name', 'mil_id', 'unit_location', 'agency_id', 'photo'], restrictSearchableAttributes: ['mil_id']});
		
		if(result.hits.length) {
			var hit = {...result.hits[0]};
			hit.title += ` (${milId})`;
			setInstructor(hit);
			setMilId('');
			setMilIdError(null);
		} else {
			setInstructor({mil_id: milId?.trim(), title: `DODID / EDIPI: ${milId?.trim()}`, unit_name: <Box>
				<b>This is not a registered user.</b>  This event can still be added, but your trainer will need to create an account to confirm this training.  Please share this link with your instructor: [<Link sx={{cursor:'pointer'}} onClick={()=>{copyToClipboard(window.location.origin); setCopyConfirm(true);}}>Copy Link</Link>]
			</Box>});
		}
		setMilId('');
		setMilIdError(null);
		
		e.target.value=""; 
	}
	
	return <Dialog open={open} onClose={close} fullWidth scroll="paper" fullScreen={isMobile} maxWidth="sm">
		<form onSubmit={handleSubmit(submit)} >
			<DialogTitle sx={{padding:0}}>
				<Stack >
					<Box px={2} pt={1} className="card-header">
						<Typography variant="h6">Record Refresher Training Event</Typography>
						
					</Box>
					<Box p={2} pb={1}>
						{wRole == roles.INSTRUCTOR && <Typography variant="body2">To record a training event and credit personnel with refresher training, please enter the date and location of the training event.</Typography>}
						{wRole == roles.GRADUATE && <Typography variant="body2">To record refresher training, please enter the date and location of the training event along with instructor details.</Typography>}
					</Box>
					<Stack direction="row" justifyContent="space-between" spacing={1} p={2}>
						<FormControl >
							<Controller control={control} name="date" rules={{required: true}} render={({field}) => <TextField  size="small" {...field} label="Date" type="date" error={!!errors.date} InputLabelProps={{shrink:true}}  />} />
						</FormControl>
						<Box flex={1}>
							<FormControl fullWidth >
								<Controller control={control} name="location" rules={{required: true}} render={({field}) => <TextField  size="small" {...field} fullWidth label="Location" type="text" error={!!errors.location} helperText={errors.location ? "Required" : undefined} InputLabelProps={{shrink:true}}  />} />
							</FormControl>
						</Box>
					</Stack>
					{hasRole(user, roles.INSTRUCTOR) && <Box px={2} pb={1}>
						<Typography variant="body1"><b>What was your role in this training event?</b></Typography>
						<Box pl={1}>
							<Controller control={control} name="training_role"  rules={{required: true}} render={({field})=><RadioGroup {...field} sx={{'& .MuiRadio-root': {paddingTop: 0.5, paddingBottom:0.5}}}>
									<FormControlLabel value={roles.INSTRUCTOR} control={<Radio />} label="I led refresher training as the Instructor" />
									<FormControlLabel value={roles.GRADUATE} control={<Radio />} label="I otherwise participated in refresher training" />
								</RadioGroup>}>
							</Controller>
						</Box>
					</Box>}
					<Divider/>
					
					{wRole == roles.INSTRUCTOR && <Box p={2} >
						<Typography variant="subtitle1">Participants</Typography>
						<Typography variant="body2">Enter the DODID / EDIPI of each participant below.  Registered accounts will receive a notification of this event.  Unregistered personnel will be able to confirm this training if and when they register an account in the future.</Typography>
					</Box>}

					{wRole == roles.GRADUATE && <Box p={2} >
						<Typography variant="subtitle1">Instructor Information</Typography>
						<Typography variant="body2">Please provide information about the instructor for this refresher training event.  Instructors must be registered in this system.  If they are not, please share the registration link with them.  [<Link sx={{cursor:'pointer'}} onClick={()=>{copyToClipboard(window.location.origin); setCopyConfirm(true);}}>Copy Link</Link>] </Typography>
					</Box>}
				</Stack>
			</DialogTitle>
			<DialogContent className="MuiTypography-body2"  dividers sx={{maxHeight: 300, minHeight: 200, padding:0, position:'relative'}}>
				<Snackbar open={copyConfirm} autoHideDuration={3000} onClose={()=>setCopyConfirm(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}  sx={{'& .MuiPaper-root': {padding:'0px 8px'}, position:"absolute", width:'90%', bottom: '8px !important', left: 0}} >
					<Alert severity="info" variant="filled" sx={{ width: '100%' }}>  
						A link has been copied to your clipboard
					</Alert>
				</Snackbar>

					{wRole == roles.INSTRUCTOR && <List >
						{participants?.map((p: any, i: number) => {
							return <ListItemButton key={i} divider >
								<Stack direction="row" justifyContent="flex-start" alignItems="center" flex={1}>
									<Box>
										<UserAvatar user={p} sx={{border:"solid 2px white", height: 30, width: 30, mr:1 }} />
									</Box>
									<Box flex={1}>
										<Typography fontWeight={500} whiteSpace="nowrap" color={p.objectID ? "" : "disabled"}>{p.title}</Typography>
										<Box className="sub-title">{p.unit_name} {p.unit_location && `| ${p.unit_location}`}</Box>
									</Box>
									<Box>
										<IconButton size="small" onClick={()=>setParticipants(participants.filter((u: any, j:number) => j != i))}><Cancel/></IconButton>
									</Box>
								</Stack>
							</ListItemButton>
						})}	
						<ListItem>
							<Stack direction="row" >
								<Box sx={{ height: 30, width: 4}}>
									
								</Box>
								<TextField fullWidth size="small" value={milId} variant="outlined" onChange={(e)=>{setMilId(e.currentTarget.value); setMilIdError(null);}} placeholder="Enter DODID / EDIPI" onKeyDown={(e)=>{ if (e.key == "Enter") {addParticipant(e);}}} error={!!milIdError} helperText={milIdError} InputProps={
									{
										sx: {pr: 0.75},
										endAdornment: <Button size="small" variant="contained" color={!milIdError ? 'success' : 'error'} sx={{paddingLeft: 2, paddingRight: 2}} startIcon={<Add />} onClick={addParticipant} >Add</Button>
									}
								} />
							</Stack>
						</ListItem>
					</List>}

					{wRole == roles.GRADUATE && <Stack spacing={2} p={2}>
						<Box >
							<Typography variant="body2">To find the instructor for this event, enter their DODID / EDIPI below.  The instructor will be notified and asked to confirm this training.</Typography>
						</Box>
						<Box maxWidth={400}>
							<TextField fullWidth size="small" value={milId} variant="outlined" onChange={(e)=>{setMilId(e.currentTarget.value); setMilIdError(null);}} placeholder="Enter DODID / EDIPI" onKeyDown={(e)=>{ if (e.key == "Enter") {addInstructor(e);}}} error={!!milIdError} helperText={milIdError} InputProps={
								{
									sx: {pr: 0.75},
									endAdornment: <Button size="small" variant="contained" color={!milIdError ? 'success' : 'error'} sx={{paddingLeft: 2, paddingRight: 2}} startIcon={<Add />} onClick={addInstructor} >Add</Button>
								}
							} />
						</Box>
						{instructor && <Box>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" flex={1}>
									<Box>
										<UserAvatar user={instructor} sx={{border:"solid 2px white", height: 30, width: 30, mr:1 }} />
									</Box>
									<Box flex={1}>
										<Typography fontWeight={500} whiteSpace="nowrap" color={instructor.objectID ? "" : "disabled"}>{instructor.title}</Typography>
										<Box className="sub-title">{instructor.unit_name} {instructor.unit_location && `| ${instructor.unit_location}`}</Box>
									</Box>
									<Box>
										<IconButton size="small" onClick={()=>setInstructor(null)}><Cancel/></IconButton>
									</Box>
								</Stack>
						</Box>}
					</Stack>}
					
				
			</DialogContent>
			<DialogActions sx={{justifyContent:"space-between", pt:0}}> 
				
				<Stack flex={1} mt={1}>
					
					{wRole == roles.INSTRUCTOR && <Box textAlign="right">
						<Controller control={control} name="include_instructor" render={({field}) =><FormControlLabel sx={{mr:0.5}} slotProps={{typography: {className:"sub-title ", fontSize:"0.9rem"}}} control={<Checkbox {...field} />} label="Include this refresher event in my training history" />}></Controller>
					</Box>}
					{generalError && <Box p={2} pt={0} flex={1} textAlign="right">
						<Typography variant="body2" color="error">{generalError}</Typography>
					</Box>}
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
						<Button size="small" onClick={close}>Cancel</Button>
						<Button size="small" type="submit" variant="contained">Record Training</Button>
					</Stack>
				</Stack>
				
			</DialogActions>
		</form>
	</Dialog>
};

export default LogRefreshTraining;